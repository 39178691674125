import "preact/debug";
import "preact/devtools";
import { render } from 'preact';
import isMobile from 'is-mobile';
import canAutoPlay from 'can-autoplay';

import { getLanguage } from "./i18n";
import PlayerContainer from './components/PlayerContainer';
import { getCacheDuration, getData, getUrlArguments } from "./api";
import { URL_SHARE, URL_TEESTER } from './config';
import { onEvent } from "./events";
import {
    getLocalSessionId,
    getPictureLink,
    isBrowserCookieScopeLimited,
    isIosDevice,
    isSafari,
    isValidMode,
    PLAYER_MODE
} from "./utils";

const filterCards = (cards = []) => {
    const byLanguage = {}

    cards.forEach((card) => {
        byLanguage[card.language] = [...(byLanguage[card.language] || []), card];
    });

    const language = (getLanguage() || navigator.userLanguage || navigator.language || 'en').split('-')[0];

    if (byLanguage[language]) {
        return byLanguage[language];
    }
    if (Object.keys(byLanguage).length === 1) {
        return cards;
    }
    return cards.en || [];
}

const chooseStreamingProtocol = (project) => {
    if ((isSafari() || isIosDevice()) && project?.lastOutput?.hlsUrl) {
        return {
            type: 'HLS',
            url: `${project?.lastOutput?.hlsUrl}${getCacheDuration() ? `?cacheduration=${getCacheDuration()}` : ''}`
        }
    } else if (!isIosDevice() && project?.lastOutput?.dashUrl) {
        return {
            type: 'DASH',
            url: `${project?.lastOutput?.dashUrl}${getCacheDuration() ? `?cacheduration=${getCacheDuration()}` : ''}`
        }
    } else if (project?.lastOutput?.url) {
        return {
            type: 'DIRECT',
            url: project?.lastOutput?.url,
        }
    }
    return {
        type: null,
        url: null,
    }
}

const getTrackingSession = (session) => {
    // Get session from the url (if the SDK provided it)
    if (session && typeof (session) === 'string' && !['null', 'false', 'true', '0', '1', 'undefined'].includes((session || '').toString().toLowerCase())) {
        return session;
    }
    // Create a temporary session if we are on fucked browser
    if (isBrowserCookieScopeLimited()) {
        return getLocalSessionId();
    }
    // Normal case, cross-cookies are allow, we disable our fallback
    return null;
}

const bindData = (project, args, configuration, configId) => {
    const { type, url } = chooseStreamingProtocol(project);
    const session = getTrackingSession(args.session);

    const ratio = project?.ratio;
    const ratioFrame = ratio === '9:16' ? { fit: 'cover', w: 720, h: 1280 } : { fit: 'cover', w: 1280, h: 720 };
    return {
        id: project?.id || null,
        url: url || null,
        type: type || 'DIRECT',
        title: project?.title || '',
        logoUrl: configuration.CUSTOM_LOGO ? configuration.JOIN_URL || null : URL_TEESTER,
        description: project?.description || '',
        shareUrl: args.link || (project?.id ? `${URL_SHARE}?v=${project?.id}${args.key ? `&key=${args.key}` : ''}` : ''),
        thumbnail: project?.poster || project?.lastOutput?.assets?.firstFrame || '',
        metaThumbnail: getPictureLink(project?.poster || project?.lastOutput?.assets?.firstFrame || '', { fit: 'cover', w: 500, h: 500 }, false),
        firstFrameUrl: getPictureLink(project?.poster || project?.lastOutput?.assets?.firstFrame || '', ratioFrame, false),
        lastFrameUrl: getPictureLink(project?.lastOutput?.assets?.lastFrame || project?.poster || project?.lastOutput?.assets?.firstFrame || null, { ...ratioFrame, blur: 25 }, false),
        introsVideo: (project.type !== 'EDITED' ? (project?.lastOutput?.assets?.intros || null) : null),
        lowStoryboard: project?.lastOutput?.assets?.lowStoryboard?.map(e => ({...e, path: getPictureLink(e.path, { q: 60 })})) || [],
        highStoryboard: project?.lastOutput?.assets?.highStoryboard?.map(e => ({...e, path: getPictureLink(e.path, { q: 60 })})) || [],
        thumbnailText1: project?.thumbnailText1 || '',
        thumbnailText2: project?.thumbnailText2 || '',
        companyName: configuration?.NAME || 'Teester',
        onEvent: (evt, projectId, arg) => onEvent(evt, projectId, arg, configId, project, session),
        allowBuy: args.allow_buy,
        allowRating: args.allow_rate,
        allowShare: args.allow_share,
        allowJoinCommunity: args.allow_ambassador,
        joinUrl: configuration.JOIN_URL || '',
        productPageUrl: project?.productUrl || '',
        autoPlay: args.autoplay || false,
        showSkeepersLogo: project.type !== 'EDITED' ? configuration.SHOW_LOGO !== '0' && (configuration.SHOW_LOGO || '').toLowerCase() !== 'false' : false,
        customLogoUrl: project.type !== 'EDITED' && configuration.CUSTOM_LOGO ? getPictureLink(configuration.CUSTOM_LOGO, { w: 70, h: 70 }, true) : null,
        color: configuration.COLOR || configuration.DARK_COLOR || configuration.LIGHT_COLOR || '#3271f5',
        thumbnailColorTopText: configuration.THUMBNAIL_COLOR_TOP_TEXT || '#000',
        thumbnailColorTopBackground: configuration.THUMBNAIL_COLOR_TOP_BACKGROUND || '#FFF',
        thumbnailColorBottomText: configuration.THUMBNAIL_COLOR_BOTTOM_TEXT || '#FFF',
        thumbnailColorBottomBackground: configuration.THUMBNAIL_COLOR_BOTTOM_BACKGROUND || '#000',
        musicUrl: (project.type !== 'EDITED' && !project.containsMusic ? (args.music || configuration.MUSIC) : '') || '',
        musicVolume: parseFloat((project.type !== 'EDITED' ? (args.music_volume || configuration.MUSIC_VOLUME || '0.1') : '0').replace(',', '.')),
        subtitles: (project?.lastOutput?.subtitles || []).filter(e => e.status === "READY"),
        cards: filterCards(project.type !== 'EDITED' ? project?.lastOutput?.cards || [] : []).map(card => ({
            ...card,
            thumbnailCard: getPictureLink(card.thumbnail, { fit: 'cover', w: 100, h: 100 }),
            thumbnailHover: getPictureLink(card.thumbnail, { fit: 'cover', w: 128, h: 72 }),
        })),
        isMobile: isMobile({ featureDetect: true, tablet: true }),
        isIos: isIosDevice(),
        session,
        videoLanguage: project?.solution?.language || 'en',
        forceSubtitle: configuration.FORCE_SUBTITLE === 'true',
        enableSmartBorders: configuration.SMART_BORDERS === 'true',
        testedAt: project?.testedAt,
        releasedAt: project?.releasedAt,
        productReferences: project?.productReferences,
        ratio,
        productTitle: project?.productTitle,
        customLogoAltText: configuration.CUSTOM_LOGO_ALT_TEXT || 'brand logo',
        mode: isValidMode(args.mode) ? args.mode : PLAYER_MODE.default,
    };
};

const detectAutoPlaySupport = async (args) => {
    if (!args.autoplay) {
        return false;
    }
    const { result } = await canAutoPlay.video({ muted: false });
    return result;
}

const main = async () => {
    const args = getUrlArguments();
    let [rawData, canAutoPlayWithSound] = await Promise.all([
        getData(args.id || null, args.key || null),
        detectAutoPlaySupport(args)
    ]);
    rawData = rawData || {};

    // Get project
    const project = rawData.getProject || {};

    // Get configuration
    let configuration = {};
    let configId = null;
    try {
        configId = rawData.getSolution ? rawData.getSolution.id : null;
        configuration = rawData.getSolution ? rawData.getSolution.configuration : rawData.getProject.defaultPlayer.configuration;
        configuration = configuration.values.reduce((acc, v) => ({ ...acc, [v.key.name]: v.computedValue }), {});
    } catch (e) { }

    const data = {
        ...bindData(project, args, configuration, configId),
    };
    window.debug = { project, args, configuration, configId, componentData: data };
    document.title = data.title;

    let playerManager = null;

    render(<PlayerContainer {...data} canAutoPlayWithSound={canAutoPlayWithSound} onInit={(cb) => {
        playerManager = cb;
    }} />, document.querySelector('#app'));

    // Catch events from SDK
    window.addEventListener('message', (evt) => {
        try {
            const data = JSON.parse(evt.data);
            if (playerManager && ['PLAY', 'PAUSE', 'MUTE', 'UNMUTE'].includes(data.type.replace('ACTION_', ''))) {
                playerManager(data.type.replace('ACTION_', ''));
            }
        }
        catch (e) { }
    });
};

/* Force reload when hash changes */
window.onhashchange = () => { window.location.reload() }

main();
